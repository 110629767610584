export const LINK_LIVEWEBSITE_INDILEARN = "https://myindilearn.com";
export const LINK_LIVEDEMO_YOURDENTALCARE = "https://yourdentalcaremks.netlify.app/";
export const LINK_LIVEDEMO_NETFLIXGPT = "https://netflixgpt-ddd0f.web.app/";
export const LINK_LIVEDEMO_INVOICEAPP = "https://my-invoice-app-two​.vercel​.app";
export const LINK_GITHUB = "https://github.com/innaanr/netflix-gpt";
export const LINK_LINKEDIN = "https://www.linkedin.com/in/innaanr/";
export const LINK_DRIBBBLE = "https://www.dribbble.com/innaanr";
export const LINK_EMAIL = "mailto:innahnr@gmail.com";
export const LINK_FREECODECAMP_JS ="https://www.freecodecamp.org/certification/fcc3f3f2f20-7f83-4add-b9cf-cbb9f3630bd1/javascript-algorithms-and-data-structures-v8";
export const LINK_FREECODECAMP_RESPONSIVE ="https://www.freecodecamp.org/certification/fcc3f3f2f20-7f83-4add-b9cf-cbb9f3630bd1/responsive-web-design";
