import {
  LINK_GITHUB,
  LINK_LIVEDEMO_NETFLIXGPT,
  LINK_LIVEDEMO_YOURDENTALCARE,
  LINK_LIVEWEBSITE_INDILEARN,
  LINK_LIVEDEMO_INVOICEAPP,
} from "./constants";
import coverIndilearn from "../assets/coverIndilearn.png";
import coverYourDental from "../assets/coverYourDental.png";
import coverNetflixClone from "../assets/coverNetflixClone.png";
import coverMyInvoice from "../assets/coverMyInvoice.png";

const dataProjects = [
  {
    info: {
      id: "1",
      img: coverIndilearn,
      title: "Indilearn's Website",
      desc: "Indilearn is an information technology company that specializes in developing academic information systems for campuses. I designed and developed their website, which features a responsive interface and functionalities to effectively present their products, services, and company information.",
      techStack: "Wordpress | Elementor",
      liveDemo: LINK_LIVEWEBSITE_INDILEARN,
    },
  },
  {
    info: {
      id: "2",
      img: coverYourDental,
      title: "Landing Page Website for Your Dental Care Clinic",
      desc: "Designed and developed a landing page website that includes the company profile for Your Dental Care Clinic.",
      techStack: "HTML | CSS/Tailwind CSS",
      liveDemo: LINK_LIVEDEMO_YOURDENTALCARE,
      github: LINK_GITHUB,
    },
  },
  {
    info: {
      id: "3",
      img: coverNetflixClone,
      title: "Movie Search App with OpenAI GPT recommendations",
      desc: "Developed movie search app equipped with GPT-based search functionality, providing a streaming experience similar to Netflix. This project combines an intuitive user interface with GPT technology to deliver more accurate content recommendations and search results.",
      techStack: "Tailwind CSS | React, Redux | Firebase Auth | OpenAI",
      liveDemo: LINK_LIVEDEMO_NETFLIXGPT,
      github: LINK_GITHUB,
    },
  },
  {
    info: {
      id: "4",
      img: coverMyInvoice,
      title: "Invoice App",
      desc: "This is an ongoing personal project aimed at enhancing my skills using modern technologies such as React, Next.js, Tailwind CSS, ShadCN UI, and PostgreSQL. I am currently working on adding more features to improve functionality and user experience.",
      techStack: "Tailwind CSS | ShadCN UI | React | Next JS | PostgreSQL",
      liveDemo: LINK_LIVEDEMO_INVOICEAPP,
      github: LINK_GITHUB,
    },
  }
  
];

export default dataProjects;
